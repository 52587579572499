import { type CSSProperties, type MutableRefObject, useEffect, useState } from 'react';

import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import Lottie from 'react-lottie-player';

import { useBreakpoint } from '~/ui/components/grid/useBreakpoint';
import { Breakpoint } from '~/ui/styles/grid';
import { Orientation, useOrientation } from '~/v1/hooks/useOrientation';
import { useScrollLock } from '~/v1/hooks/useScrollLock';
import { useThemeContext } from '~/v1/system/theme/theme.context';
import { lottieColors } from '~/v1/utils/format';

import styles from './logo.module.scss';
import lottieJsonLandscape from './logoAnimationLandscape.json';
import lottieJsonPortrait from './logoAnimationPortrait.json';

const OVERLAY_REMOVAL_DURATION = 500;
const SHRINK_ANIMATION_START = 2200;
const SHRINK_ANIMATION_START_MOBILE_TABLET = 2550;

type LogoIntroProps = {
  logoRef: MutableRefObject<HTMLElement | null>;
  onDone: () => void;
};

export const LogoIntro: React.FC<LogoIntroProps> = ({ logoRef, onDone }) => {
  const theme = useThemeContext();
  const [updateCount, setUpdateCount] = useState(0);
  const breakpoint = useBreakpoint();
  const isMobileTablet = breakpoint == Breakpoint.SM || breakpoint == Breakpoint.MD;
  const orientation = useOrientation();
  const lottieJson =
    orientation === Orientation.PORTRAIT ? lottieJsonPortrait : lottieJsonLandscape;
  const [lottieStyle, setLottieStyle] = useState<CSSProperties | undefined>();
  const [hasOverlay, setHasOverlay] = useState(true);
  const [withLottie, setWithLottie] = useState(true);

  useEffect(() => {
    const lottieClr = lottieColors(theme.secondary.substring(1));

    if (lottieJson.layers[0].shapes[0].it[1].c?.k) {
      lottieJson.layers[0].shapes[0].it[1].c.k = lottieClr;
    }

    if (lottieJson.layers[0].shapes[0].it[2].c?.k) {
      lottieJson.layers[0].shapes[0].it[2].c.k = lottieClr;
    }

    // lottie need animation needs to be rerendered once the color is updated
    setUpdateCount(i => i + 1);
  }, [theme.secondary, lottieJson]);

  const adjustLottie = () => {
    if (logoRef.current) {
      const newLottieStyleDesktop = {
        width: logoRef.current?.offsetWidth,
        transform: `translateY(${-(
          window.innerHeight - logoRef.current?.getBoundingClientRect().bottom
        )}px)`,
      };
      const newLottieStyleMobileTablet = {
        width: logoRef.current?.offsetWidth,
        transform: `translate(0, ${-(
          window.innerHeight - logoRef.current?.getBoundingClientRect().bottom
        )}px)`,
      };
      setLottieStyle(isMobileTablet ? newLottieStyleMobileTablet : newLottieStyleDesktop);
    }
  };

  const animationStart = () => {
    setTimeout(
      adjustLottie,
      isMobileTablet ? SHRINK_ANIMATION_START_MOBILE_TABLET : SHRINK_ANIMATION_START,
    );
  };

  const removeLottie = () => {
    setWithLottie(false);
  };

  const removeOverlay = () => {
    setHasOverlay(false);
    setTimeout(removeLottie, OVERLAY_REMOVAL_DURATION);
  };

  const onLottieComplete = () => {
    removeOverlay();
    onDone();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { ref } = useScrollLock(withLottie);

  return (
    logoRef && (
      <AnimatePresence>
        {hasOverlay && (
          <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: OVERLAY_REMOVAL_DURATION / 1000 }}
            className={styles.animationOverlay}
            style={{ background: `${theme.primary}` }}
            ref={ref as MutableRefObject<HTMLDivElement>}
          >
            {withLottie && (
              <Lottie
                // lottie need animation needs to be rerendered once the color or breakpoint is updated
                key={`${theme.secondary}-${updateCount}`}
                onLoad={animationStart}
                className={cx(styles.lottie)}
                loop={false}
                animationData={lottieJson}
                play
                style={lottieStyle}
                onComplete={onLottieComplete}
              />
            )}
          </motion.div>
        )}
      </AnimatePresence>
    )
  );
};
