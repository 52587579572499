import { type Home_spotlightCTALink } from '~/v1/_types/Home';
import { ExternalEventType } from '~/v1/_types/globalTypes';
import { getArticleCTA } from '~/v1/components/cards/article/article.utils';
import { ContentType } from '~/v1/constants/contentType';
import { Route } from '~/v1/constants/route';

import { type HomeSpotlightCTA } from './home';

export function mapSpotlightCTALink(link: Home_spotlightCTALink): HomeSpotlightCTA {
  switch (link.__typename) {
    case ContentType.NESTED_IDEA:
      return {
        headline: link.question,
        eyebrow: link.name,
        buttonLabel: 'Explore the Idea',
        buttonLink: `${Route.IDEA}/${link.slug}`,
      };
    case ContentType.NESTED_ARTICLE:
      const cta = getArticleCTA(link);
      return {
        headline: link.title,
        eyebrow: link.eyebrow as string,
        buttonLabel: cta.ctaText,
        buttonLink: cta.ctaUrl,
      };
    case ContentType.NESTED_GRANT_ACTIVITY_ARTICLE:
      return {
        headline: link.title,
        eyebrow: link.granteeNameOverride ?? link.grant.granteeName,
        buttonLabel: 'Read the grant story',
        buttonLink: `${Route.GRANT_STORY}/${link.slug}`,
      };
    case ContentType.EXTERNAL_EVENT:
      return {
        headline: link.name,
        eyebrow: getFriendlyEventTypeName(link.eventType),
        buttonLabel: 'See the event',
        buttonLink: link.link,
      };
    case ContentType.FEATURE:
      return {
        headline: link.tagline,
        eyebrow: link.name,
        buttonLabel: 'Explore the Special Feature',
        buttonLink: `${Route.FEATURE}/${link.slug}`,
      };
  }
  // TODO: Investigate why this switch isn't exhaustive
  throw Error('Invalid Spotlight CTA Link type');
}

/**
 * Returns a friendly name for the given {@link ExternalEventType}.
 *
 * @param eventType The {@link ExternalEventType} that will determine the friendly name.
 */
function getFriendlyEventTypeName(eventType: ExternalEventType): string {
  switch (eventType) {
    case ExternalEventType.EVENT:
      return 'Event';
    case ExternalEventType.VIRTUAL_EVENT:
      return 'Virtual Event';
  }
}
