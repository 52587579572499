import { Column, Grid } from '~/ui/components/grid';
import { Link } from '~/v1/components/link/link';
import { Route } from '~/v1/constants/route';

import styles from './mission.module.scss';

export interface MissionSectionProps {
  missionText: string;
}

export const MissionSection: React.FC<MissionSectionProps> = ({ missionText }) => {
  return (
    <Grid className="section">
      <Column sm={5} md={6} lg={6} offsetLeft={{ lg: 1 }}>
        <div className={styles.textWrapper}>
          <h2 className="pageTitleSmallTypography">{missionText}</h2>
          <Link href={Route.GRANT_MAKING_AREAS} className={styles.link} withIcon>
            View grantmaking areas
          </Link>
        </div>
      </Column>
    </Grid>
  );
};
