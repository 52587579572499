import { type ContentType } from '~/v1/constants/contentType';

type Content = {
  __typename: string;
};

/**
 * Groups the list of content based on the given list of related {@link ContentType}.
 * Consecutive, related content items will be grouped into their own lists.
 * Unrelated content items will not be grouped and form their own singletons.
 *
 * For example, given content types `A`, `B`, and `C`, this content:
 * ```
 * [A, A, B, C, A, C, B]
 * ```
 *
 * and the list of content types to group with:
 * ```
 * [B, C]
 * ```
 *
 * the output would be:
 * ```
 * [[A], [A], [B, C], [A], [C, B]]
 * ```
 *
 * @param content The content being group based on their {@link ContentType}.
 * @param relatedContentTypes The list of related {@link ContentType} to use for grouping.
 * @returns A list of groups of content based on the related content types.
 */
export function groupByRelatedContent<T extends Content>(
  content: T[],
  relatedContentTypes: ContentType[],
): T[][] {
  return content.reduce<T[][]>((acc, curr) => {
    const previousGroup = acc[acc.length - 1] || [];
    const previousContentType = previousGroup[previousGroup.length - 1]?.__typename as ContentType;
    const currentContentType = curr.__typename as ContentType;
    if (
      relatedContentTypes.includes(previousContentType) &&
      relatedContentTypes.includes(currentContentType)
    ) {
      previousGroup.push(curr);
    } else {
      acc.push([curr]);
    }

    return acc;
  }, []);
}
