import { useEffect, useState } from 'react';

import cx from 'classnames';

import styles from './fade.module.scss';

const TRANSITION_DURATION = 1600;

interface IntroFadeProps {
  onDone: () => void;
}

export const IntroFade: React.FC<IntroFadeProps> = ({ onDone }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    let t = setTimeout(() => {
      setIsActive(false);

      t = setTimeout(() => {
        setIsVisible(false);
        onDone();
      }, TRANSITION_DURATION);
    }, TRANSITION_DURATION);

    return () => clearTimeout(t);
  }, [onDone]);

  return isVisible ? (
    <div
      className={cx(styles.root, {
        [styles.active]: isActive,
      })}
    />
  ) : null;
};
