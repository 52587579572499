import cx from 'classnames';

import { type AnalyticsModule } from '~/ui/components/analytics';
import { Column, Grid } from '~/ui/components/grid';
import { type RelatedArticle } from '~/v1/components/cards/article/article.interface';
import { mapArticleCard } from '~/v1/components/cards/article/article.utils';
import { EmphasizedCard } from '~/v1/components/cards/emphasized/emphasized';

import styles from './emphasized.module.scss';

interface EmphasizedModuleProps {
  data: RelatedArticle;
  className?: string;
  title?: string;
  cardClass?: string;
  isEventsPage?: boolean;
  gtm?: {
    module: AnalyticsModule;
  };
}

export const EmphasizedModule: React.FC<EmphasizedModuleProps> = ({
  data,
  title,
  cardClass,
  className,
  gtm,
  isEventsPage = false,
}) => {
  return (
    <Grid className={className || 'section'}>
      <Column offsetLeft={{ lg: 1 }} sm={5} md={7} lg={7}>
        {title && <h2 className={cx(styles.title, 'sectionTitleTypography')}>{title}</h2>}

        <EmphasizedCard
          className={cardClass}
          isEventsPage={isEventsPage}
          gtm={gtm}
          {...mapArticleCard(data)}
        />
      </Column>
    </Grid>
  );
};
