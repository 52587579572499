import cx from 'classnames';

import { Column, Grid } from '~/ui/components/grid';
import { type Home_inFocusContent } from '~/v1/_types/Home';
import { ContentType } from '~/v1/constants/contentType';
import { EmphasizedModule } from '~/v1/modules/emphasized/emphasized';
import { RelatedGridModule } from '~/v1/modules/grid/related/related';
import relatedStyles from '~/v1/modules/grid/related/related.module.scss';
import { QuoteModule } from '~/v1/modules/quote/quote';
import { groupByRelatedContent } from '~/v1/utils/content';

export interface HomeInFocusContentProps {
  inFocusHeadline: string;
  inFocusContent: Home_inFocusContent[];
}

export function HomeInFocusContentSection({
  inFocusHeadline,
  inFocusContent,
}: HomeInFocusContentProps): React.ReactNode {
  const groupedInFocusContent = groupByRelatedContent(inFocusContent, [
    ContentType.EXTERNAL_EVENT,
    ContentType.NESTED_ANNUAL_REPORT,
    ContentType.NESTED_ARTICLE,
    ContentType.NESTED_GRANT_ACTIVITY_ARTICLE,
  ]);

  return (
    <>
      <Grid>
        <Column sm={5} md={5} lg={5} offsetLeft={{ lg: 1 }}>
          <h3 className={cx('modulesTitleTypography', relatedStyles.title)}>{inFocusHeadline}</h3>
        </Column>
      </Grid>

      {groupedInFocusContent.map((inFocusContent, index) => {
        if (inFocusContent.length > 1) {
          return <RelatedGridModule key={index} content={inFocusContent} />;
        } else {
          const data = inFocusContent[0];
          switch (data.__typename) {
            case ContentType.EXTERNAL_EVENT:
            case ContentType.NESTED_ANNUAL_REPORT:
            case ContentType.NESTED_ARTICLE:
            case ContentType.NESTED_GRANT_ACTIVITY_ARTICLE:
            case ContentType.NESTED_IDEA:
              return <RelatedGridModule key={index} content={inFocusContent} />;
            case ContentType.EMPHASIZED_ARTICLE:
              return <EmphasizedModule key={index} data={data.article} />;
            case ContentType.QUOTE:
              return <QuoteModule key={index} quote={data} />;
          }
        }
      })}
    </>
  );
}
