import { useSyncExternalStore } from 'react';

export enum Orientation {
  PORTRAIT,
  LANDSCAPE,
}

export function useOrientation(): Orientation {
  return useSyncExternalStore(
    (onStoreChange: () => void) => {
      window.addEventListener('resize', onStoreChange);
      return () => window.removeEventListener('resize', onStoreChange);
    },
    () => (window.innerWidth > window.innerHeight ? Orientation.LANDSCAPE : Orientation.PORTRAIT),
    () => Orientation.PORTRAIT,
  );
}
