import { type GetStaticProps } from 'next';

import { HomeContainer, type HomeContainerProps } from '~/v1/containers/home/home';
import { getHomepage } from '~/v1/graphql/actions/server/getHomepage';
import { getTheme } from '~/v1/graphql/actions/server/getTheme';
import { Layout } from '~/v1/system/layout/layout';

type HomePageProps = HomeContainerProps;

const HomePage: React.FC<HomeContainerProps> = ({ data }) => {
  return (
    <>
      <Layout
        navigation={data.mainNavigation}
        featuredSearches={data.featuredSearches}
        footer={data.footer}
        isHomepage
      >
        <HomeContainer data={data} />
      </Layout>
    </>
  );
};

export default HomePage;

export const getStaticProps: GetStaticProps<HomePageProps> = async () => {
  const theme = await getTheme();
  const { data } = await getHomepage();

  if (!data.home) {
    return {
      notFound: true,
    };
  }
  return {
    props: {
      data,
      theme,
    },
  };
};
