import Head from 'next/head';
import { Fragment, useEffect, useRef } from 'react';

import { useReactiveVar } from '@apollo/client';
import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import { AnalyticsSpotlight, Event } from '~/ui/components/analytics';
import { trackEvent } from '~/ui/components/analytics/trackEvent';
import { Column, Grid } from '~/ui/components/grid';
import { useBreakpoint } from '~/ui/components/grid/useBreakpoint';
import { Breakpoint } from '~/ui/styles/grid';
import { type Home } from '~/v1/_types/Home';
import { type HomepageQuery } from '~/v1/_types/HomepageQuery';
import Logo from '~/v1/assets/image/mellonLogo.svg';
import { mapAccordionCards } from '~/v1/components/accordion/grantActivity/card/card.utils';
import { Button } from '~/v1/components/button/button';
import { ButtonMode, ButtonType } from '~/v1/components/button/button.interface';
import { CallToAction } from '~/v1/components/callToAction/callToAction';
import { FullBleedCTA, FullBleedCTATrigger } from '~/v1/components/fullBleedCta/fullBleedCta';
import { Image } from '~/v1/components/image/image';
import { Intro } from '~/v1/components/intro/intro';
import { navigationOpenedVar } from '~/v1/components/navigation/navigation';
import { NewsLetterId } from '~/v1/components/newsletter/newsletter.interface';
import spotlightStyles from '~/v1/components/spotlight/spotlight.module.scss';
import spotlightVideoStyles from '~/v1/components/spotlight/video/video.module.scss';
import { Route } from '~/v1/constants/route';
import { mapSpotlightCTALink } from '~/v1/containers/home/home.utils';
import { HomeInFocusContentSection } from '~/v1/containers/home/inFocusContent';
import { GrantActivityAccordionModule } from '~/v1/modules/accordion/grantActivity/grantActivity';
import { NewsCarouselModule } from '~/v1/modules/carousel/newsCarousel/newsCarousel';
import { NewsletterModule } from '~/v1/modules/newsletter/newsletter';
import { SpotlightModule } from '~/v1/modules/spotlight/spotlight';

import styles from './home.module.scss';
import { LayoutManager, isLayout } from './layoutManager';
import { MissionSection } from './mission/mission';

export interface HomeContainerProps {
  data: HomepageQuery;
}

export interface HomeSpotlightCTA {
  headline: string;
  eyebrow: string | null;
  buttonLink?: string;
  buttonLabel: string;
}

export function HomeContainer({ data }: HomeContainerProps): React.ReactElement {
  const {
    inFocusContent,
    inFocusHeadline,
    ctaText,
    grantActivityArticles,
    spotlightImage,
    spotlightVideo,
    spotlightCTALink,
    thematicSpotlight,
    missionText,
    newsletter,
    inTheNews,
    layout,
  } = data.home as Home;

  if (!isLayout(layout)) {
    throw Error(`Invalid layout: '${layout}'`);
  }

  const breakpoint = useBreakpoint();
  const scrollTrackerRef = useRef<HTMLDivElement>(null);
  const navigationOpened = useReactiveVar(navigationOpenedVar);
  const logoRef = useRef(null);

  const { headline, eyebrow, buttonLink, buttonLabel } = mapSpotlightCTALink(spotlightCTALink);
  const Headline = Breakpoint.SM !== breakpoint ? 'h2' : 'h3';

  useEffect(() => {
    const scrollTracker = scrollTrackerRef.current;
    if (!scrollTracker) {
      return;
    }

    let isFirstRun = true;
    const handleScroll = () => {
      // On the first run after soft navigation, the element is aligned with the bottom of the screen.
      // https://mellonfoundation.atlassian.net/browse/BE-2396
      if (isFirstRun) {
        isFirstRun = false;
        return;
      }
      const rect = scrollTracker.getBoundingClientRect();
      const scrollTop = rect.top > 0 ? rect.top : 0;
      const scrollProgress = 1 - scrollTop / window.innerHeight;
      document.documentElement.style.setProperty('--scroll-progress', scrollProgress.toString());
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Fragment>
      <Intro logoRef={logoRef} />
      <div className={cx(styles.heroContainer, 'section')}>
        <div className={styles.mellonLogo}>
          <AnimatePresence initial={false}>
            {!navigationOpened && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3, delay: 0.2 }}
              >
                <Grid>
                  <Column sm={4} md={5} lg={9}>
                    <div ref={logoRef} className={cx(styles.mellonLogoInner, 'overflowLeft')}>
                      <Logo />
                    </div>
                  </Column>
                </Grid>
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        <div
          className={cx(spotlightStyles.spotlight, {
            [spotlightStyles.spotlightFullHeight]: breakpoint === Breakpoint.SM,
          })}
        >
          <div
            className={cx(spotlightStyles.spotlightInner, {
              [spotlightStyles.spotlightFullHeight]: breakpoint !== Breakpoint.SM,
            })}
          >
            {spotlightImage ? (
              <Image
                {...spotlightImage}
                className={cx(spotlightStyles.image, spotlightStyles.imageZoomOut)}
                src={spotlightImage.src}
                alt={spotlightImage.description ?? ''}
                aspectRatio="5/4"
                colSpan={{
                  [Breakpoint.SM]: 5,
                  [Breakpoint.MD]: 7,
                  [Breakpoint.LG]: 9,
                }}
                priority
              />
            ) : spotlightVideo ? (
              <video
                src={spotlightVideo.video?.src}
                className={cx(spotlightStyles.previewVideo, spotlightVideoStyles.videoWrapper)}
                autoPlay
                controls={false}
                loop
                muted
                playsInline
                disablePictureInPicture
                disableRemotePlayback
              />
            ) : null}

            <div className={spotlightStyles.gradient} />

            <FullBleedCTA label={headline} className={spotlightStyles.content}>
              <Grid>
                <Column sm={5} md={5} lg={5} offsetLeft={{ lg: 2 }}>
                  {eyebrow && <div className={spotlightStyles.eyebrow}>{eyebrow}</div>}
                  {headline && (
                    <Headline
                      className={cx(
                        'truncate-3',
                        'sectionTitleTypography',
                        spotlightStyles.headline,
                      )}
                    >
                      {headline}
                    </Headline>
                  )}
                  <FullBleedCTATrigger>
                    <Button
                      type={ButtonType.Primary}
                      href={buttonLink}
                      mode={Breakpoint.SM === breakpoint ? ButtonMode.Light : ButtonMode.Dark}
                      ariaLabel={buttonLabel}
                      onClick={() =>
                        trackEvent(Event.SPOTLIGHT_CLICK, {
                          spotlightType: AnalyticsSpotlight.HOMEPAGE,
                          spotlightTitle: headline,
                          spotlightLink: buttonLink,
                        })
                      }
                    >
                      {buttonLabel}
                    </Button>
                  </FullBleedCTATrigger>
                </Column>
              </Grid>
            </FullBleedCTA>
          </div>
        </div>

        <div ref={scrollTrackerRef} />
      </div>

      <Head>
        <title>Mellon Foundation</title>
        <meta name="description" content={missionText} />
      </Head>

      <LayoutManager
        layout={layout}
        mission={<MissionSection missionText={missionText} />}
        highlightedContent={
          <>
            <div className={cx(styles.highlightedContent, !thematicSpotlight && 'section')}>
              <HomeInFocusContentSection
                inFocusHeadline={inFocusHeadline}
                inFocusContent={inFocusContent}
              />
            </div>

            {thematicSpotlight && <SpotlightModule spotlight={thematicSpotlight} />}
          </>
        }
        cardGrid={
          <HomeInFocusContentSection
            inFocusHeadline={inFocusHeadline}
            inFocusContent={inFocusContent}
          />
        }
        grantStoriesAccordion={
          !!grantActivityArticles.length ? (
            <GrantActivityAccordionModule
              className="section"
              accordionCards={mapAccordionCards(grantActivityArticles)}
            />
          ) : null
        }
        callToAction={
          <NewsletterModule id={NewsLetterId.HOME} newsletter={newsletter}>
            <Column sm={5} md={4} lg={4} offsetLeft={{ lg: 1 }}>
              <CallToAction
                className={styles.callToAction}
                href={Route.GRANT_RESOURCES}
                buttonLabel="About our grantmaking process"
                text={ctaText}
              />
            </Column>
          </NewsletterModule>
        }
      />

      <NewsCarouselModule title="In the news" news={inTheNews} />
    </Fragment>
  );
}
