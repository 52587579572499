export type Layout = 'Default' | 'Thematic';

export function isLayout(layout: string): layout is Layout {
  return layout === 'Default' || layout === 'Thematic';
}

export interface LayoutManagerProps {
  layout: Layout;
  mission: JSX.Element;
  highlightedContent: JSX.Element;
  cardGrid: JSX.Element;
  grantStoriesAccordion: JSX.Element | null;
  callToAction: JSX.Element;
}

/**
 * Manages the layout of the components defined in the props based on the given
 * layout.
 *
 * Any new layout type must be added to the {@link Layout} type and rendered
 * here.
 *
 * @param props The layout and components that will be rendered.
 * @returns The rendered components based on the layout.
 */
export function LayoutManager({
  layout,
  cardGrid,
  highlightedContent,
  mission,
  grantStoriesAccordion,
  callToAction,
}: LayoutManagerProps): JSX.Element {
  switch (layout) {
    case 'Default':
      return (
        <>
          {mission}
          {cardGrid}
          {grantStoriesAccordion}
          {callToAction}
        </>
      );
    case 'Thematic':
      return (
        <>
          {highlightedContent}
          {mission}
        </>
      );
  }
}
