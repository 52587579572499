import { type MutableRefObject, useContext } from 'react';

import Cookies from 'js-cookie';

import { StorageKey } from '~/v1/constants/storageKey';
import { LayoutClient } from '~/v1/system/layout/client/client';
import { ThemeContext } from '~/v1/system/theme/theme.context';

import { IntroFade } from './fade/fade';
import { LogoIntro } from './logo/logo';

type IntroProps = {
  logoRef: MutableRefObject<HTMLElement | null>;
};

export const Intro: React.FC<IntroProps> = ({ logoRef }) => {
  const {
    // We only want to use the root theme for the intro animation. Any other theme is page-specific
    rootTheme: { primary, isFullScreenAnimation },
  } = useContext(ThemeContext);

  const shouldRun = Cookies.get(StorageKey.INTRO_ANIMATION) !== primary;

  const onComplete = () => Cookies.set(StorageKey.INTRO_ANIMATION, primary, { expires: 1 });

  const IntroComponent = isFullScreenAnimation ? (
    <LogoIntro logoRef={logoRef} onDone={onComplete} />
  ) : (
    <IntroFade onDone={onComplete} />
  );

  return <LayoutClient>{shouldRun ? IntroComponent : null}</LayoutClient>;
};
